import { onMounted, onUnmounted } from 'vue'

// options definition
interface WindowEventOption {
  immediate: boolean
}

export default function useEventListener(
  eventName: keyof WindowEventMap,
  handle: EventListener,
  options?: WindowEventOption
) {
  onMounted(() => {
    window.addEventListener(eventName, handle)

    if (options !== undefined && options.immediate) {
      handle(new Event(eventName))
    }
  })

  onUnmounted(() => {
    window.removeEventListener(eventName, handle)
  })
}
