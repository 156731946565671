export default function useCams() {
  function getSourceUriCsp(id: number): string {
    return `https://observomanager.csp.it/hls/public/${id}/index.m3u8`
  }

  function getSourceUriAlp(id: number | string): string {
    return `https://webcam.alp.net:8080/hls/${id}/index.m3u8`
  }

  return {
    getSourceUriAlp,
    getSourceUriCsp,
  }
}
