export default function useServices() {
  function getImage(url: string): string {
    if (process.env.NODE_ENV == 'development') return `${url}?`

    return `/get-image/?i=${encodeURI(url)}`
  }

  function grabImage(url: string): string {
    if (process.env.NODE_ENV == 'development') return `${url}?`

    return `/grab-image/?i=${encodeURI(url)}`
  }

  function resetBalmaCamera(): Promise<Response> {
    return fetch(`/reset-camera/`)
  }

  return {
    getImage,
    grabImage,
    resetBalmaCamera,
  }
}
