import useServices from '@/hooks/useService'
import { State } from '@/types'
import {
  faCamera,
  faTemperatureHalf,
  faVideoCamera,
} from '@fortawesome/free-solid-svg-icons'

const { getImage, grabImage, resetBalmaCamera } = useServices()

const state: State = {
  webcams: [
    {
      id: 'balma',
      label: 'Rf. Balma',
      icon: faCamera,
      title: 'Rifugio Alpe della Balma',
      description: 'Forno di coazze',
      sources: [
        {
          type: 'image',
          uri: getImage('http://194.116.8.136:82/record/current.jpg'),
          follow: true,
          actions: {
            Reset: resetBalmaCamera,
          },
        },
        {
          type: 'image',
          uri: grabImage('https://www.rifugiobalma.it/web-cam-a-monte/'),
          follow: true,
          followInterval: 60000,
        },
      ],
    },
    {
      id: 'fontana_mura',
      label: 'Fontana Mura',
      icon: faVideoCamera,
      title: 'Rifugio Fontana Mura',
      description: 'Forno di coazze',
      sources: [
        {
          type: 'video',
          cspId: 134,
          alpId: '58529d1fe82abbb909000278',
        },
      ],
    },
    {
      id: 'borgata_tonda',
      label: 'Bta. Tonda',
      icon: faVideoCamera,
      title: 'Borgata Tonda',
      description: "Vallone dell'Indiritto",
      sources: [
        {
          type: 'video',
          cspId: 163,
          alpId: '57a451cfe82abbf9730003ac',
        },
      ],
    },
    {
      id: 'aquila',
      label: 'Aquila',
      icon: faVideoCamera,
      title: 'Aquila di Giaveno',
      sources: [
        {
          type: 'video',
          cspId: 4,
        },
      ],
    },
    {
      id: 'punta_aquila',
      label: "Pta. dell'Aquila",
      icon: faVideoCamera,
      title: "Punta dell'Aquila",
      sources: [
        {
          type: 'video',
          cspId: 160,
          alpId: '5f9be0c0e82abbaf68000000',
        },
      ],
    },
    {
      id: 'pian_dell_lese',
      label: 'Pian delle Lese',
      icon: faVideoCamera,
      title: 'Pian delle Lese',
      description: 'Aquila di Giaveno',
      sources: [
        {
          type: 'video',
          cspId: 38,
          alpId: '577bb45fe82abb4a0b000248',
        },
        {
          type: 'video',
          cspId: 39,
          alpId: '5ce3f033e82abba05a000003',
        },
        {
          type: 'video',
          cspId: 89,
          alpId: '62ed2eb931cfc21d17135fa3',
        },
        {
          type: 'video',
          cspId: 138,
          alpId: '564a0ad6e82abb947e00000b',
        },
      ],
    },
    {
      id: 'ceresey',
      label: 'Loc. Ceresey',
      icon: faVideoCamera,
      title: 'Località Ceresey',
      description: 'Forno di Coazze',
      sources: [
        {
          type: 'video',
          cspId: 114,
          alpId: '5c1a10c6e82abb864e00013d',
        },
      ],
    },
  ],

  meteo: {
    id: 'meteo_robinet',
    icon: faTemperatureHalf,
    uri: 'https://www.meteomont.org/meteomont/online/stazioni/valori/0021MONTEROBINET.jpg',
    label: 'Stazione meteo',
    link: 'https://www.meteomont.org/meteomont/online/stazioni/Stazioni.asp?cod=21',
    title: 'Monte Robinet',
    description: 'Rete di rilevamento automatica del Comando Truppe Alpine',
  },

  credits: [
    {
      name: 'I1YRB - TechnoHut.org',
      url: 'http://alp.net/',
    },
    {
      name: 'CSP Research & Development',
      url: 'http://rd.csp.it/',
    },
    {
      name: 'CAI Coazze',
      url: 'http://www.caicoazze.com/',
    },
    {
      name: 'Supporto Meteorologico alla Forza Armata',
      url: 'http://www.meteomont.org/',
    },
  ],
}

export default state
